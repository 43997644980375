<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Pedido de Inserção</h1>
        <!--end::Title-->
        <!--end::Title-->
        <div class="mt-3" v-if="insertionOrders.length > 0">
          <p class="mb-0">
            <strong>Resultados:</strong> {{ insertionOrders.length }}/{{ total }}
          </p>
        </div>
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <router-link class="btn btn-sm btn-bg-primary btn-color-white" :to="{ name: 'insertion.orders.create' }"><i class="fa-solid fa-plus"></i> Criar PI</router-link>
        <!--begin::Filter menu-->
        <div class="m-0">
          <!--begin::Menu toggle-->
          <a
            href="#"
            @click.prevent="isFiltersOpen = !isFiltersOpen"
            class="btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
          >
          <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
          <span class="svg-icon svg-icon-6 svg-icon-muted me-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
            </svg>
          </span>
          <!--end::Svg Icon-->Filtrar</a>
          <!--end::Menu toggle-->
          <!--begin::Menu 1-->
          <div
            class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
            :class="{
              'show': isFiltersOpen
            }"
            style="position: absolute; top: 40px; right: 0;"
          >
            <!--begin::Header-->
            <div class="px-7 py-5">
              <div class="fs-5 text-dark fw-bold">Opções de Filtro</div>
            </div>
            <!--end::Header-->
            <!--begin::Menu separator-->
            <div class="separator border-gray-200"></div>
            <!--end::Menu separator-->
            <!--begin::Form-->
            <div class="px-7 py-5">
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Busca:</label>
                <p style="font-size: 11px;">(ID, empresa, anunciante ou usuário)</p>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <input type="text" class="form-control form-control-solid" placeholder="" v-model.trim="filters.search"/>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Status:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <select class="form-select" aria-label="Select example" v-model="filters.status">
                    <option value="">Todos</option>
                    <option value="WAITING_PAYMENT">Aguardando pagamento</option>
                    <option value="EXPIRED">Expirado</option>
                    <option value="PAID">Pago</option>
                    <option value="PENDING">Pendente cliente</option>
                    <option value="REFUSED">Recusado</option>
                  </select>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Data:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <div class="d-flex align-items-start justify-content-between">
                    <div class="">
                      <input class="form-control form-control-solid date_picker_date" v-model.trim="filters.start_date" />
                      <div class="mt-3">
                        <button type="button" class="btn btn-sm btn-primary" @click="filters.start_date = ''" v-if="filters.start_date">Limpar</button>
                      </div>
                    </div>
                    <div class="px-4 mt-4">-</div>
                    <div class="">
                      <input class="form-control form-control-solid date_picker_date" v-model.trim="filters.end_date" />
                      <div class="mt-3">
                        <button type="button" class="btn btn-sm btn-primary" @click="filters.end_date = ''" v-if="filters.end_date">Limpar</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Valor:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="">
                      <Money
                        class="form-control mb-3"
                        v-model.trim="filters.amount_start"
                        v-bind="money"
                      />
                    </div>
                    <div class="px-4">-</div>
                    <div class="">
                      <Money
                        class="form-control mb-3"
                        v-model.trim="filters.amount_end"
                        v-bind="money"
                      />
                    </div>
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Actions-->
              <div class="d-flex justify-content-end">
                <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" @click="reset">Resetar</button>
                <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true" @click="applyFilters">Aplicar</button>
              </div>
              <!--end::Actions-->
            </div>
            <!--end::Form-->
          </div>
          <!--end::Menu 1-->
        </div>
        <!--end::Filter menu-->
        <!--begin::Secondary button-->
        <!--end::Secondary button-->
      </div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <template v-if="loader && insertionOrders.length === 0">
          <div class="py-5">
            <Loader size="big" />
          </div>
        </template>
        <template v-else-if="!loader && insertionOrders.length === 0">
          <p class="text-center pt-5">Nenhum pedido de inserção encontrado</p>
        </template>
        <template v-else>
          <div class="table-responsive">
            <!--begin::Table-->
            <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
              <!--begin::Table head-->
              <thead>
                <!--begin::Table row-->
                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  <th class="min-w-100px">Empresa</th>
                  <th class="min-w-100px">Anunciante</th>
                  <th class="min-w-100px">Valor</th>
                  <th class="min-w-70px">Perfil de cobrança</th>
                  <th class="min-w-70px">Forma de pagamento</th>
                  <th class="min-w-70px">Status</th>
                  <th class="min-w-70px">Ativo</th>
                  <th class="min-w-70px">Ações</th>
                </tr>
                <!--end::Table row-->
              </thead>
              <!--end::Table head-->
              <!--begin::Table body-->
              <tbody class="fw-semibold text-gray-600">
                <!--begin::Table row-->
                <tr v-for="(iOrder, index) in insertionOrders" :key="index">
                  <td>
                    <span>
                      <router-link
                        :to="{ name: 'companies.show', params: { id: iOrder.advertiser.company.id } }"
                      >
                        {{ iOrder.advertiser.company.name }}
                      </router-link>
                    </span>
                  </td>
                  <td>
                    <span>
                      <router-link
                        :to="{ name: 'advertisers.show', params: { id: iOrder.advertiser.id } }"
                      >
                        {{ iOrder.advertiser.name }}
                      </router-link>
                    </span>
                  </td>
                  <td>
                    <span>{{ iOrder.amount/100 | formatMoney(iOrder.advertiser.currency_type) }}</span>
                  </td>
                  <td>
                    <span>{{ iOrder.billing_profile }}</span>
                  </td>
                  <td>
                    <span>{{ iOrder.payment_method == 'DEPOSIT' ? 'DEPÓSITO' : 'BOLETO' }}</span>
                  </td>
                  <td>
                    <span v-if="iOrder.order">{{ iOrder.order.payment_status | formatPaymentStatus }}</span>
                    <span v-else>Pendente</span>
                  </td>
                  <td>
                    <span>{{ iOrder.is_active ? 'Sim' : 'Não' }}</span>
                  </td>
                  <td>
                    <span>
                      <router-link
                        :to="{ name: 'insertion.orders.show', params: { id: iOrder.id } }"
                        class="btn btn-sm btn-primary"
                      >
                        <i class="fas fa-eye px-0"></i>
                      </router-link>
                    </span>
                  </td>
                </tr>
                <!--end::Table row-->
              </tbody>
              <!--end::Table body-->
            </table>
          </div>
          <!--end::Table-->
          <div class="text-center py-5">
            <Button
              class="btn-primary"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              Carregar mais
            </Button>
          </div>
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import InsertionOrdersService from '@/modules/insertion-orders/services/insertion-orders-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'
import { Money } from 'v-money'

export default {
  components: {
    Button,
    Loader,
    MainLayout,
    Money
  },
  metaInfo () {
    return {
      title: 'PI'
    }
  },
  data () {
    return {
      insertionOrders: [],
      filters: {
        amount_start: '',
        amount_end: '',
        end_date: '',
        start_date: '',
        search: '',
        status: ''
      },
      isFiltersOpen: false,
      isLoadMoreVisible: false,
      loader: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      page: 1,
      total: 0
    }
  },
  created () {
    this.getInsertionOrders()
  },
  mounted () {
    document.getElementsByClassName('date_picker_date').forEach(item => {
      item.flatpickr({
        dateFormat: 'd/m/Y'
      })
    })
  },
  methods: {
    /**
     * Apply filters and search
     */
    applyFilters () {
      this.isFiltersOpen = false
      this.page = 1
      this.insertionOrders = []
      this.getInsertionOrders()
    },
    /**
     * Get insertion orders
     */
    async getInsertionOrders () {
      this.loader = true
      try {
        const insertionOrders = await InsertionOrdersService.getInsertionOrders({
          amount_end: this.filters.amount_end,
          amount_start: this.filters.amount_start,
          end_date: this.filters.end_date,
          start_date: this.filters.start_date,
          search: this.filters.search,
          status: this.filters.status,
          page: this.page
        })
        this.insertionOrders = [...this.insertionOrders, ...insertionOrders.data]
        this.isLoadMoreVisible = insertionOrders.meta.current_page !== insertionOrders.meta.last_page
        this.total = insertionOrders.meta.total
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getInsertionOrders()
    },
    /**
     * Reset
     */
    reset () {
      this.isFiltersOpen = false
      this.filters.search = ''
      this.page = 1
      this.insertionOrders = []
      this.getInsertionOrders()
    }
  }
}
</script>
